import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import GraphData from "./Graph/GraphData";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../../../Loader";
import AIPrice from "./AIPrice";
import { bindActionCreators } from "redux";
import {
  fetchAiPriceGenerator,
  fetchDashboardData,
  pushAIPrice,
  singleDateScrape,
} from "../../../../actions/dashboard.action";
import { connect } from "react-redux";
import UpdateDialog from "../../../../compenents/update-dialog";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { eventCardStying } from "../../../../util/commonStyling";
import Switch from "@material-ui/core/Switch";
import { purple } from "@material-ui/core/colors";

import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";

import EventsCalendarDialog from "./EventsCalendar";
import { fetchHotelEvents } from "../../../../actions/events.action";
import { compareEventDates } from "../../../../util/commonFunctions";
import { Accordion } from "semantic-ui-react";
import AiIcon from "../../../../images/AiIcon.jpg";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "#777777",
    fontSize: theme.typography.pxToRem(12),
    width: "auto",
    lineHeight: "20px",
  },
}))(Tooltip);

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[500],
    "&$checked": {
      color: purple[900],
    },
    "&$checked + $track": {
      backgroundColor: purple[900],
    },
  },
  checked: {},
  track: {},
})(Switch);

am4core.useTheme(am4themes_animated);

class AccordionBottomSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      inputValue: "",
      showButton: false,
      hotelData: null,
      startDate: new Date(),
      updatePriceDialog: false,
      updatePriceIndex: null,
      eventStartDate: new Date(),
      showCalendarDialog: false,
      eventFetchLoading: false,
      hotelEvents: [],
      showEvents: false,
    };
    this.regex = /^[0-9]{0,3}$/;
    this.aiPriceList = [];
    this.hotelId = null;
  }
  componentDidMount() {
    this.setState({ hotelData: this.props.hotelData }, () => {
      this.getHotelEvents();
    });
    this.initialiseAiPriceList(this.props.hotelData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hotelData !== this.props.hotelData) {
      this.initialiseAiPriceList(this.props.hotelData);
      this.setState({ hotelData: this.props.hotelData });
    }
  }
  initialiseAiPriceList = (hotelData) => {
    this.aiPriceList = [];
    this.hotelId = hotelData.id;
    for (let i = 0; i < hotelData.prices.length; i++) {
      let obj = {
        date: moment(hotelData.prices[i].from_Date).format("yyyy-MM-DD"),
        price: hotelData.prices[i].aiPrice,
      };
      this.aiPriceList.push(obj);
    }
  };
  setAiPriceList = (index, price) => {
    this.aiPriceList[index].price = price;
  };

  openPriceUpdateDialog = (index, item) => {
    this.setState((prevState) => ({
      updatePriceDialog: true,
      updatePriceIndex: index,
    }));
  };

  updateAIPriceList = async (price, reason) => {
    const { updateAIPrice, fetchDashboardData , searchText, pageNumber} = this.props;
    console.log("qwqwqwqwqw",this.state)
    try {
      const response = await updateAIPrice(
        {
          ...this.aiPriceList[this.state.updatePriceIndex],
          price,
          reason,
        },
        this.hotelId
      );
      this.updatePriceDialogHandler();
      await fetchDashboardData(
        moment(this.state.startDate).format("yyyy-MM-DD"),
        true,
        pageNumber,
        searchText
      );
    } catch (ex) {
      this.updatePriceDialogHandler();
    }
  };

  updatePriceDialogHandler = (priceList = []) => {
    this.setState((prevState) => ({
      updatePriceDialog: !prevState.updatePriceDialog,
      priceListUpdate: priceList,
    }));
  };

  handleOpenEventsCalendarDialog = () => {
    this.setState({ showCalendarDialog: true });
  };

  handleCloseEventsCalendarDialog = () => {
    this.setState({ showCalendarDialog: false });
  };

  getHotelEvents = async () => {
    try {
      if (this.props.mainIndex === this.props.activeIndex) {
        this.setState({ eventFetchLoading: true });
        const { startMonthDate, endMonthDate } = this.getEventsDateLabel();
        const { events } = await this.props.fetchHotelEvents(
          {
            startDate: startMonthDate,
            endDate: endMonthDate,
            hotelId: this.state.hotelData.id,
          },
          false
        );

        this.setState({
          hotelEvents: events?.sort(compareEventDates).map((event) => ({
            ...event,
            eventDateFormated: moment(event.eventDate).format(
              "ddd, MMM DD YYYY"
            ),
          })),
          eventFetchLoading: false,
        });
      }
    } catch {
      this.setState({ eventFetchLoading: false });
    }
  };

  getEventsDateLabel = () => {
    const { eventStartDate } = this.state;

    const firstMonthLabel = moment(eventStartDate).format("MMM");
    const secondMonthLabel = moment(eventStartDate)
      .add(1, "months")
      .format("MMM");
    const thirdMonthLabel = moment(eventStartDate)
      .add(2, "months")
      .format("MMM");
    const year = moment(eventStartDate).add(2, "months").format("YYYY");

    const isEventStartDateIsToday =
      moment(new Date()).format("MMM YYYY") ===
      moment(eventStartDate).format("MMM YYYY");

    const label = `${firstMonthLabel}, ${secondMonthLabel}, ${thirdMonthLabel} ${year}`;
    return {
      dateLabel: label,
      startMonthDate: isEventStartDateIsToday
        ? moment(eventStartDate).format("l")
        : moment(eventStartDate).startOf("month").format("l"),
      endMonthDate: moment(eventStartDate)
        .add(2, "months")
        .endOf("month")
        .format("l"),
      isEventStartDateIsToday,
    };
  };

  changeEventStartDate = (type) => {
    const { eventStartDate } = this.state;
    if (type === "ADD") {
      this.setState(
        {
          eventStartDate: new Date(
            eventStartDate.setMonth(eventStartDate.getMonth() + 3)
          ),
        },
        () => this.getHotelEvents()
      );
    } else {
      this.setState(
        {
          eventStartDate: new Date(
            eventStartDate.setMonth(eventStartDate.getMonth() - 3)
          ),
        },
        () => this.getHotelEvents()
      );
    }
  };

  render() {
    if (this.props.mainIndex != this.props.activeIndex) {
      return <></>;
    }

    const { noOfDays, mainIndex } = this.props;
    const {
      hotelData,
      updatePriceDialog,
      eventFetchLoading,
      hotelEvents,
      eventStartDate,
      showEvents,
    } = this.state;

    const { dateLabel, isEventStartDateIsToday } = this.getEventsDateLabel();

    if (this.state.hotelData) {
      return (
        <React.Fragment>
          {this.state.showCalendarDialog && (
            <EventsCalendarDialog
              handleClose={this.handleCloseEventsCalendarDialog}
              eventStartDate={eventStartDate}
              hotelId={hotelData.id}
            />
          )}
          <UpdateDialog
            open={updatePriceDialog}
            updateHandler={this.updateAIPriceList}
            dialogClose={this.updatePriceDialogHandler}
            moduleName="Price"
          />
          <div className="accordion-bottom-section">
            <div className="left-container">
              {hotelData &&
                !!hotelData.prices.map((v) => v.occupancy).filter((v) => !!v)
                  .length && (
                  <div className="hotel-bottom-container occupancy">
                    <div className="occupancy-title">
                      <p>Occupancy Rate</p>
                    </div>
                  </div>
                )}
              <div className="hotel-bottom-container AIPrice">
                <div className="AIprice-title">
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div>
                      <img src="/images/AIPrice.svg" alt="price" />
                      AI Recommended Price
                    </div>
                    {hotelData?.pmS_Used === "AIS" && (
                      <div
                        style={{cursor: "pointer", color: "#6f42c1", textDecoration: "underLine"}}
                        onClick={() => this?.props?.pushAIPrice(hotelData?.id)}
                      >
                        Push AI Price
                      </div>
                    )}
                    {/* <Tooltip title="Fetch AI Price">
                      <div
                        onClick={() =>
                          this?.props?.fetchAiPriceGenerator(hotelData?.id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          style={{ width: "25px" }}
                          src={AiIcon}
                          alt="AIIcon"
                        />
                      </div>
                    </Tooltip> */}
                  </p>

                  {/* {hotelData.pmS_Used && hotelData.pmS_Used != "Other" && (
                    <button
                      className="price update"
                      onClick={() =>
                        this.updatePriceDialogHandler(this.aiPriceList)
                      }
                    >
                      Update Price
                    </button>
                  )} */}
                </div>
              </div>

              {/* <Accordion> */}
              {/* <Accordion.Title
                  active={showEvents}
                  index={0}
                  onClick={() => this.setState({ showEvents: !showEvents })}
                > */}
              <div className="py-2">
                <strong style={{ fontSize: "14px" }}>Show Events</strong>
                <PurpleSwitch
                  checked={showEvents}
                  onChange={() => this.setState({ showEvents: !showEvents })}
                  name="checkedA"
                />{" "}
              </div>
              {/* </Accordion.Title> */}

              <Collapse in={showEvents}>
                <div className="hotel-events-container">
                  <div className="hotel-events-header">
                    <div className="hotel-events-header-1">
                      <ArrowBackIosIcon
                        onClick={() =>
                          isEventStartDateIsToday
                            ? null
                            : this.changeEventStartDate("SUBTRACT")
                        }
                        style={
                          isEventStartDateIsToday
                            ? { color: "gray", cursor: "not-allowed" }
                            : {}
                        }
                      />
                      <p>{dateLabel}</p>
                      <ArrowForwardIosIcon
                        onClick={() => this.changeEventStartDate("ADD")}
                      />
                    </div>

                    <div
                      className="hotel-events-header-2"
                      onClick={this.handleOpenEventsCalendarDialog}
                    >
                      <CalendarTodayIcon />
                      <p>Calandar</p>
                    </div>
                  </div>

                  <div className="hotel-events-list">
                    {eventFetchLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        {" "}
                        <CircularProgress />{" "}
                      </div>
                    ) : hotelEvents?.length ? (
                      hotelEvents?.map((event, index) => (
                        <EventCard
                          key={event.data + index}
                          onClickRefreshScrapeDate={() =>
                            this.props?.singleDateScrape(
                              hotelData?.id,
                              moment(event?.eventDate).format("YYYY-MM-DD")
                            )
                          }
                          event={event}
                          index={index}
                        />
                      ))
                    ) : (
                      <div className="text-center pt-2">
                        <img
                          src="/images/noEvent.svg"
                          alt="no events"
                          style={{ height: "100px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
              {/* </Accordion> */}
            </div>
            <div className="right-container">
              <ul className="occupancy-data-container">
                {hotelData &&
                  !!hotelData.prices.map((v) => v.occupancy).filter((v) => !!v)
                    .length &&
                  hotelData.prices.map((item, index) => {
                    return (
                      <HtmlTooltip
                        key={item.id}
                        title={
                          <div
                            style={{
                              width: "319px",
                              backgroundColor: "#fff",
                              border: "1px solid #dadde9",
                              padding: "16px",
                              borderRadius: "3px",
                            }}
                          >
                            <ul
                              className="top-container"
                              style={{
                                display: "inline-block",
                                width: "49%",
                              }}
                            >
                              <li
                                className="percentage"
                                style={{
                                  display: "block",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  marginBottom: "8px",
                                }}
                              >
                                Occupancy {Math.round(item.occupancy)}%{" "}
                              </li>
                              <li
                                className="percentage"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                Total Room : {item.totalRooms}{" "}
                              </li>
                              <li
                                className="date"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                Occupied Rooms: {item.totalSold}
                              </li>
                              <li className="date" style={{ display: "block" }}>
                                Out Of Order Rooms: {item.ood}
                              </li>
                            </ul>
                            <ul
                              className="top-container"
                              style={{
                                display: "inline-block",
                                width: "49%",
                                verticalAlign: "top",
                              }}
                            >
                              <li
                                className="date"
                                style={{
                                  marginBottom: "8px",
                                  display: "block",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                }}
                              >
                                {moment(item.from_Date).format(
                                  "dddd DD MMM, YYYY"
                                )}
                              </li>
                            </ul>
                          </div>
                        }
                        placement="bottom"
                      >
                        <li key={index}>
                          {Math.round(item.occupancy)}%
                          {item.occupancyDiff !== 0 && (
                            <span
                              className={`varibale-price ${
                                item.occupancyDiff > 0 ? "positive" : ""
                              }`}
                            >
                              {Math.round(item.occupancyDiff)}%
                            </span>
                          )}
                        </li>
                      </HtmlTooltip>
                    );
                  })}
              </ul>
              <ul className="AIprice-data-container">
                {hotelData &&
                  hotelData.prices.map((item, index) => {
                    return (
                      <AIPrice
                        openPriceUpdateDialog={this.openPriceUpdateDialog}
                        key={index}
                        mainIndex={mainIndex}
                        index={index}
                        item={item}
                      />
                    );
                  })}
              </ul>
              <GraphData
                hotelData={hotelData}
                noOfDays={noOfDays}
                mainIndex={mainIndex}
              />
            </div>
          </div>

          <div className="accordion-bottom-container">
            {hotelData &&
              hotelData.competitors.map((item, index) => {
                return (
                  <div className="competitor-container">
                    <div className="left-container">
                      <p>{item.name}</p>
                    </div>
                    <ul className="right-container">
                      {item.price.map((item, index) => {
                        return (
                          <HtmlTooltip
                            title={
                              <div
                                style={{
                                  width: "319px",
                                  backgroundColor: "#fff",
                                  border: "1px solid #dadde9",
                                  padding: "16px",
                                  borderRadius: "3px",
                                }}
                              >
                                <ul
                                  className="top-container"
                                  style={{
                                    display: "inline-block",
                                    width: "49%",
                                  }}
                                >
                                  <li
                                    className="percentage"
                                    style={{
                                      display: "block",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    Rate: {item.price} USD{" "}
                                  </li>
                                  <li
                                    style={{
                                      display: "block",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {item.description1}{" "}
                                  </li>
                                  <li style={{ display: "block" }}>
                                    {item.description2}
                                  </li>
                                </ul>
                                <ul
                                  className="top-container"
                                  style={{
                                    display: "inline-block",
                                    width: "49%",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <li
                                    className="date"
                                    style={{
                                      marginBottom: "8px",
                                      display: "block",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      verticalAlign: "top",
                                      textAlign: "right",
                                    }}
                                  >
                                    {moment(item.from_Date).format(
                                      "dddd DD MMM, YYYY"
                                    )}
                                  </li>
                                </ul>
                              </div>
                            }
                            placement="bottom"
                          >
                            <li key={`${mainIndex}_${index}`}>${item.price}</li>
                          </HtmlTooltip>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>
        </React.Fragment>
      );
    }
    return <Loader />;
  }
}

const EventCard = ({ onClickRefreshScrapeDate, event, index }) => {
  return (
    <div
      className="event-card"
      style={
        index % 2 === 0
          ? eventCardStying["evenIndexStyle"]
          : eventCardStying["oddIndexStyle"]
      }
    >
      <div className="event-info-container">
        <p className="event-name">
          <strong>{event.eventName}</strong>
        </p>
        <p className="event-date">{event.eventDateFormated}</p>
      </div>
      <div className="event-price-info-container">
        <p>${event?.price || 0}</p>
        <div onClick={() => onClickRefreshScrapeDate?.()}>
          <img src="/images/refresh.svg" alt="refresh" />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardData: bindActionCreators(fetchDashboardData, dispatch),
  fetchHotelEvents: bindActionCreators(fetchHotelEvents, dispatch),
  fetchAiPriceGenerator: bindActionCreators(fetchAiPriceGenerator, dispatch),
  pushAIPrice: bindActionCreators(pushAIPrice, dispatch),
  singleDateScrape: bindActionCreators(singleDateScrape, dispatch),
});

export default connect(null, mapDispatchToProps)(AccordionBottomSection);
